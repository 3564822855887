// Don't remove this code this is final one
import React, { useState, useReducer } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../images/logo.webp";
import RequiredRegistrationCard from "./RequiredRegistrationCard";
import ReferrerCard from "./ReferrerCard";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const SidebarNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, toggleModal] = useReducer((prev) => !prev, false);

  const isRestaurantInfoComplete = JSON.parse(localStorage.getItem('restaurantInfoComplete')) || false;
  const isMenuOperationalComplete = JSON.parse(localStorage.getItem('menuOperationalComplete')) || false;
  const isRestaurantDocumentsComplete = JSON.parse(localStorage.getItem('restaurantDocumentsComplete')) || false;
  const isContractPageComplete = JSON.parse(localStorage.getItem('contractComplete')) || false;

  const [referrerModal, toggleReferrerModal] = useReducer(
    (prev) => !prev,
    false
  );
  const [showLogoutModal, toggleShowLogoutModal] = useReducer(
    (prev) => !prev,
    false
  );
  const confirmLogout = () => {
    toggleShowLogoutModal();
    logoutService("Session");
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  // Close the sidebar when a link is clicked
  const handleLinkClick = (path) => {
    setIsMenuOpen(false); // Close the menu
    navigate(path); // Navigate to the target page
  };

  return (
    <>
      <div className="fixed w-full bg-white shadow-md flex items-center justify-between p-2 lg:p-4 z-20">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <img src={Logo} alt="Logo" className="h-12" />
          </div>
          <div className="hidden lg:flex items-center">
            <button
              className="border-green-600 text-green-600 py-2 px-10 rounded cursor-pointer border"
              onClick={toggleShowLogoutModal}
            >
              Logout
            </button>
          </div>
          <div className="lg:hidden">
            {/* Mobile Menu Toggle Button */}
            <button onClick={toggleMenu} className="text-green-600 focus:outline-none">
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2x" />
            </button>
          </div>
        </div>
      </div>
      <nav
        className={`fixed top-0 left-0 h-full bg-gray-100 p-5 pt-16 pb-20 lg:w-1/3 lg:static transition-transform duration-300 transform ${isMenuOpen ? "translate-x-0 w-full" : "-translate-x-full"
          } lg:translate-x-0 z-10`} // Full width for mobile view
      >
        <div className="bg-gray-100 w-full h-full">
          <div className="steps bg-white shadow-lg rounded-lg pt-8 pb-4 mb-6">
            <h2 className="text-xl text-gray-800 mb-3 px-5">
              Complete your registration
            </h2>
            <hr className="border-gray-300" />
            <ul className="mt-2 space-y-2">

              {/* Restaurant Information */}
              <li
                className={`flex px-5 py-2 items-center space-x-3 
                  ${location.pathname === "/register" ? "bg-gray-100" : "hover:bg-gray-50"} 
                  cursor-pointer`}
                onClick={() => handleLinkClick("/register")}
              >
                <div className="text-2xl">&#127828;</div>
                <div className='text-green-600'>
                  Restaurant information
                  <p className="text-sm font-bold text-gray-500">
                    Name, location and contact number
                  </p>
                </div>
              </li>

              {/* Menu and Operational Details - accessible if Restaurant Info is complete */}
              <li
                className={`flex px-5 py-2 items-center space-x-3 
                  ${isRestaurantInfoComplete || location.pathname === "/menu-operational" ? "bg-gray-100 cursor-pointer" : "cursor-not-allowed"} 
                  ${location.pathname === "/menu-operational" ? "bg-gray-100" : ""}`}
                onClick={isRestaurantInfoComplete || location.pathname === "/menu-operational" ? () => handleLinkClick("/menu-operational") : null}
              >
                <div className="text-2xl">&#128221;</div>
                <div className={`text-green-600 ${!isRestaurantInfoComplete && location.pathname !== "/menu-operational" ? "text-gray-400" : ""}`}>
                  Menu and Operational Details
                </div>
              </li>

              {/* Restaurant Documents - accessible if Menu and Operational Details is complete */}
              <li
                className={`flex px-5 py-2 items-center space-x-3 
                  ${isMenuOperationalComplete || location.pathname === "/restaurant-documents" ? "hover:bg-gray-100 cursor-pointer" : "cursor-not-allowed"} 
                  ${location.pathname === "/restaurant-documents" ? "bg-gray-100" : ""}`}
                onClick={isMenuOperationalComplete || location.pathname === "/restaurant-documents" ? () => handleLinkClick("/restaurant-documents") : null}
              >
                <div className="text-2xl">&#128194;</div>
                <div className={`text-green-600 ${!isMenuOperationalComplete && location.pathname !== "/restaurant-documents" ? "text-gray-400" : ""}`}>
                  Restaurant documents
                </div>
              </li>

              {/* Partner Contract - accessible if Restaurant Documents is complete */}
              <li
                className={`flex px-5 py-2 items-center space-x-3 
                  ${isRestaurantDocumentsComplete || location.pathname === "/contract" ? "hover:bg-gray-100 cursor-pointer" : "cursor-not-allowed"} 
                  ${location.pathname === "/contract" ? "bg-gray-100" : ""}`}
                onClick={isRestaurantDocumentsComplete || location.pathname === "/contract" ? () => handleLinkClick("/contract") : null}
              >
                <div className="text-2xl">&#128196;</div>
                <div className={`text-green-600 ${!isRestaurantDocumentsComplete && location.pathname !== "/contract" ? "text-gray-400" : ""}`}>
                  Partner Contract
                </div>
              </li>

            </ul>
          </div>

          <RequiredRegistrationCard onClickCard={toggleModal} />
          <ReferrerCard handleYes={toggleReferrerModal} handleNo={() => { }} />

          {/* Logout button in the sidebar for mobile view */}
          <div className="mt-10 flex justify-center md:hidden">
            <button
              className="border-green-600 text-green-600 py-2 px-10 rounded cursor-pointer border"
              onClick={toggleShowLogoutModal}
            >
              Logout
            </button>
          </div>

          {modal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
          {referrerModal && (
            <ReferrerModal
              toggleReferModal={toggleReferrerModal}
              onClickSuccess={() => {
                toggleReferrerModal();
              }}
            />
          )}
          {showLogoutModal && (
            <LogoutModel
              onClickCancel={toggleShowLogoutModal}
              onClickLogout={confirmLogout}
            />
          )}

        </div >
      </nav >
    </>
  );
};

export default SidebarNav;


/* eslint-disable no-restricted-globals */
// import { Link, useLocation } from "react-router-dom";
// import Logo from "../images/logo.webp";
// import RequiredRegistrationCard from "./RequiredRegistrationCard";
// import ReferrerCard from "./ReferrerCard";
// import { useReducer } from "react";
// import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
// import ReferrerModal from "./ReferrerModal";
// import { logoutService } from "../services/LogoutService";
// import LogoutModel from "./LogoutModel";

// const SidebarNav = () => {
//   const location = useLocation();
//   const [modal, toggleModal] = useReducer((prev) => !prev, false);

//   const [referrerModal, toggleReferrerModal] = useReducer(
//     (prev) => !prev,
//     false
//   );
//   const [showLogoutModal, toggleShowLogoutModal] = useReducer(
//     (prev) => !prev,
//     false
//   );
//   const confirmLogout = () => {
//     toggleShowLogoutModal();
//     logoutService("Session");
//   };
//   return (
//     <>
//       <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between">
//         <div className="flex items-center ms-4 p-3">
//           <img src={Logo} alt="Logo" className="h-12" />
//         </div>
//         <div className="flex items-center me-4">
//           <button
//             className=" border-green-600 text-green-600 py-2 px-10 rounded cursor-pointer border"
//             onClick={toggleShowLogoutModal}
//           >
//             Logout
//           </button>
//         </div>
//       </header>
//       <nav className="w-1/3 h-full bg-gray-100 mt-10 p-5 overflow-y-auto pt-12 pb-20">
//         <div className="bg-gray-100  w-full h-full">
//           <div className="steps bg-white shadow-lg rounded-lg py-3 mb-6">
//             <h2 className="text-xl text-grald mb-3 px-5">
//               Complete your registration
//             </h2>
//             <hr className="border-gray-300" />
//             <ul className="mt-5 space-y-7">
//               <Link to="/register" className="doc-content">
//                 <li
//                   className={
//                     location.pathname === "/register"
//                       ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
//                       : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
//                   }
//                 >
//                   <div className="text-2xl">&#127828;</div>
//                   <div>
//                     Restaurant information
//                     <p className="text-sm font-bold text-gray-500">
//                       Name, location and contact number
//                     </p>
//                   </div>
//                 </li>
//               </Link>
//               <Link to="/menu-operational" className="doc-content">
//                 <li
//                   className={
//                     location.pathname === "/menu-operational"
//                       ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
//                       : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
//                   }
//                 >
//                   <div className="text-2xl">&#128221;</div>
//                   <div>Menu and Operational Details</div>
//                 </li>
//               </Link>
//               <Link to="/restaurant-documents" className="doc-content">
//                 <li
//                   className={
//                     location.pathname === "/restaurant-documents"
//                       ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
//                       : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
//                   }
//                 >
//                   <div className="text-2xl">&#128194;</div>
//                   <div>Restaurant documents</div>
//                 </li>
//               </Link>
//               <Link to="/contract" className="doc-content">
//                 <li
//                   className={
//                     location.pathname === "/contract"
//                       ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
//                       : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
//                   }
//                 >
//                   <div className="text-2xl">&#128196;</div>
//                   <div>Partner Contract</div>
//                 </li>
//               </Link>
//             </ul>
//           </div>
//           <RequiredRegistrationCard onClickCard={toggleModal} />
//           <ReferrerCard handleYes={toggleReferrerModal} handleNo={() => {}} />
//           {modal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
//           {referrerModal && (
//             <ReferrerModal
//               toggleReferModal={toggleReferrerModal}
//               onClickSuccess={() => {
//                 toggleReferrerModal();
//               }}
//             />
//           )}
//           {showLogoutModal && (
//             <LogoutModel
//               onClickCancel={toggleShowLogoutModal}
//               onClickLogout={confirmLogout}
//             />
//           )}
//         </div>
//       </nav>
//     </>
//   );
// };

// export default SidebarNav;

