
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { verifyOtp, reSendOtp } from '../services/AuthApi';
import { handleError } from '../services/errorHandler';
import { useNavigate } from 'react-router-dom';
import { updateApiBaseUrl } from '../services/Api';

const Otp = ({ onClose }) => {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resendTimer, setResendTimer] = useState(60);
  const inputRefs = useRef(new Array(6).fill(null));
  const navigate = useNavigate();
  const uniqueAttemptId = localStorage.getItem("uniqueAttemptId");
  const [showOtpExpiredMessage, setShowOtpExpiredMessage] = useState(false);
  const [showTerminatePopup, setShowTerminatePopup] = useState(false);

  // Initialize max resend attempts
  const maxResendAttempts = 3;
  const [resendAttempts, setResendAttempts] = useState(maxResendAttempts);

  const [otpDetails, setOtpDetails] = useState(JSON.parse(localStorage.getItem("otpDetails")) || {});



  const handleTerminate = () => {
    setShowTerminatePopup(true);
  }
  const handleClosePopup = () => {
    setShowTerminatePopup(false);
  }
  const handleTerminateConfirm = () => {
    navigate('/login')
  }



  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current digit only

      // Update the OTP state
      setOtp(newOtp);

      // Move focus to the previous input, but don't clear it
      if (index > 0 && otp[index] === "") {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    let expiryTimer, resendTimerInterval;
    const currentTimestamp = Date.now();

    const timeUntilExpiry = otpDetails.otpExpiry - currentTimestamp;
    const timeUntilResend = otpDetails.otpResendWindow - currentTimestamp;

    // Set OTP expiry timer
    if (timeUntilExpiry > 0) {
      expiryTimer = setTimeout(() => {
        setOtpExpired(true);
        if (resendAttempts > 0) {
          setShowOtpExpiredMessage(true); // Show message only if resend attempts are available
        }
      }, timeUntilExpiry);
    } else {
      setOtpExpired(true); // If already expired
      if (resendAttempts > 0) {
        setShowOtpExpiredMessage(true);
      }
    }

    // Start countdown for resend timer
    if (timeUntilResend > 0) {
      setResendTimer(Math.ceil(timeUntilResend / 1000));
      resendTimerInterval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(resendTimerInterval);
            setIsResendEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      setIsResendEnabled(true); // If already past resend window
      setResendTimer(0);
    }

    return () => {
      clearTimeout(expiryTimer);
      clearInterval(resendTimerInterval);
    };
  }, [otpDetails, otpExpired, resendAttempts]);

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5) inputRefs.current[index + 1]?.focus();
    } else if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      inputRefs.current[index]?.focus();
    }
  };

  const handleVerifyOtp = async () => {
    const otpString = otp.join('');
    if (otpString.length !== 6) {
      setErrorMessage("Please enter the full 6-digit OTP");
      setTimeout(() => setErrorMessage(''), 5000); // Clear error after 5 seconds
      return;
    }

    if (otpExpired) {
      setErrorMessage("OTP expired. Please resend to get a new one.");
      setTimeout(() => setErrorMessage(''), 5000); // Clear error after 5 seconds
      return;
    }

    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!phoneNumber) return;

    try {
      const response = await verifyOtp(phoneNumber, otpString, uniqueAttemptId);
      const { accessToken, refreshToken, accessTokenExpiry, refreshTokenExpiry, restaurants, endpoint } = response || {};

      if (response.status === 404) {
        setErrorMessage("Wrong OTP entered.");
        setTimeout(() => setErrorMessage(''), 6000); // Clear error after 5 seconds
      }

      // Clear resend attempts from localStorage on successful verification
      localStorage.removeItem('otpRetriesLeft');

      localStorage.setItem("endpoint", endpoint);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
      localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);
      localStorage.setItem("restaurant_Id", restaurants);

      updateApiBaseUrl();
      onClose(false);
      navigate('/register');
    } catch (error) {
      handleError(error);
    }
  };

  const handleResendOtp = async () => {
    if (!isResendEnabled || resendAttempts <= 0) return;

    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!phoneNumber) return;

    try {
      const response = await reSendOtp(phoneNumber, 'resend', uniqueAttemptId);
      const { otpExpiry, otpResendWindow } = response;

      // Update OTP details in localStorage with new expiry and resend window times
      const newOtpDetails = {
        otpExpiry: Date.now() + otpExpiry * 1000,
        otpResendWindow: Date.now() + otpResendWindow * 1000
      };
      localStorage.setItem("otpDetails", JSON.stringify(newOtpDetails));
      setOtpDetails(newOtpDetails);

      setOtp(Array(6).fill(''));
      inputRefs.current[0]?.focus();

      setOtpExpired(false);
      setIsResendEnabled(false);
      setResendTimer(60);

      // Decrement resend attempts and update local storage
      const newResendAttempts = resendAttempts - 1;
      setResendAttempts(newResendAttempts);
      localStorage.setItem('otpRetriesLeft', newResendAttempts);

      if (newResendAttempts <= 0) {
        setIsResendEnabled(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">OTP Verification</h2>
          <button
            // onClick={() => onClose(false)}
            onClick={handleTerminate}
            className="text-gray-500 hover:text-gray-700">
            <FontAwesomeIcon icon={faTimes} />
          </button>

        </div>

        {showTerminatePopup && (
          <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 '>
            <div className='bg-white p-6 rounded-lg shadow-lg text-center'>
              <h3 className='text-lg font-bold mb-4'>Are You Sure Want to Terminate the verification?</h3>
              <div className='flex justify-center gap-4'>
                <button onClick={handleTerminateConfirm} className='bg-green-400 text-white px-20 py-2 rounded hover:bg-green-700 '>
                  Yes
                </button>
              </div>
              <div className='flex justify-center mt-5'>
                <button onClick={handleClosePopup} className='bg-gray-300 px-20 py-2 rounded hover:bg-gray-400'>
                  No

                </button>
              </div>



            </div>

          </div>
        )}





        <div className="text-center">
          <p className="text-gray-500">Check text messages for your OTP</p>
          <div className="flex justify-center mt-4 space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(el) => inputRefs.current[index] = el}
              />
            ))}
          </div>
          {otpExpired && showOtpExpiredMessage && resendAttempts > 0 && (
            <p className="text-red-500 text-sm font-bold mt-3">
              OTP expired. Please resend to get a new one.
            </p>
          )}
          {errorMessage && <p className="text-red-500 font-bold mt-2">{errorMessage}</p>}
          <div className="mt-2">
            <button
              className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
              onClick={handleVerifyOtp}
              disabled={otp.join('').length !== 6}
            >
              Verify OTP
            </button>
          </div>
          <div className="mt-2 text-gray-500">
            Not received OTP?{' '}
            <button
              className={`text-red-500 font-bold hover:underline ${isResendEnabled && resendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
              onClick={handleResendOtp}
              disabled={!isResendEnabled || resendAttempts <= 0}
            >
              Resend Now
            </button>
            {resendTimer > 0 && (
              <p className="text-gray-500 text-sm font-bold mt-2">
                Resend available in {resendTimer}s
              </p>
            )}
            {resendAttempts <= 0 && (
              <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
