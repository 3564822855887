import React, { useState } from 'react';
import Logo from '../images/logo.webp';
import { logoutService } from '../services/LogoutService';
import LogoutModel from './LogoutModel';
import { APP_NAME } from '../constants';

function CompleteSign() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogoutClick = () => setShowLogoutModal(true);
  const email = localStorage.getItem("email");

  const confirmLogout = () => {
    setShowLogoutModal(false);
    logoutService('Session');
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
        <img src={Logo} alt="Logo" className="h-12" />
        <button
          className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
          onClick={handleLogoutClick}
        >
          Logout
        </button>
      </header>

      <div className="text-center mt-48 text-2xl">
        <p>You have successfully completed the onboarding process.</p>
        <p className="mt-3 text-green-500">Welcome to The {APP_NAME} Family!</p>
        <p className="mt-4">
          You will receive your copy of the merchant agreement over {email}.
          <br />
          Someone from our team will get in touch with you shortly to help you go
          live on our platform.
        </p>
      </div>

      {showLogoutModal && (
        <LogoutModel
          onClickCancel={() => setShowLogoutModal(false)}
          onClickLogout={confirmLogout}
        />
      )}
    </div>
  );
}

export default CompleteSign;