// import React from 'react';

// function NerworkErrorPage() {
//     const refreshPage = () => {
//         window.location.reload();
//     }
//     return (
//         <div>
//             <div
//                 style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "100vh",
//                     textAlign: "center",
//                     backgroundColor: "#f8f9fa",
//                 }}
//             >
//                 <h1 style={{ color: "#ff4d4f" }}>No Internet Connection</h1>
//                 <p>Please check your internet connection and try again.</p>
//                 <button
//                     onClick={refreshPage}
//                     style={{
//                         padding: "10px 20px",
//                         backgroundColor: "#1890ff",
//                         color: "white",
//                         border: "none",
//                         borderRadius: "5px",
//                         cursor: "pointer",
//                         marginTop: "20px",
//                     }}
//                 >
//                     Retry
//                 </button>
//             </div>
//         </div>
//     )
// }

// export default NerworkErrorPage




import React from "react";

function NetworkErrorPage() {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                textAlign: "center",
                backgroundColor: "#f8f9fa",
            }}
        >
            <h1 style={{ color: "#ff4d4f" }}>No Internet Connection</h1>
            <p>Please check your internet connection and try again.</p>
            <button
                onClick={refreshPage}
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#1890ff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "20px",
                }}
            >
                Retry
            </button>
        </div>
    );
}

export default NetworkErrorPage;
