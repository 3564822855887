// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import "./App.css";
// import Home from "./components/Home";
// import Registration from "./components/Registration";
// import RestaurantDocuments from "./components/RestaurantDocuments";
// import MenuOperational from "./components/MenuOperational";
// import Contract from "./components/Contract";
// import SignDocument from "./components/SignDocument";
// import CompleteSign from "./components/CompleteSign";
// import RedirectToLastPage from "./utils/RedirectToLastPage";
// import TrackLastPage from "./utils/TrackLastPage";
// import Layout from "./components/Layout";

// function App() {

//   return (
//     <Router>
//       <RedirectToLastPage />
//       <TrackLastPage />



//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route element={<Layout />}>
//           <Route path="/register" element={<Registration />} />
//           <Route path="/menu-operational" element={<MenuOperational />} />
//           <Route
//             path="/restaurant-documents"
//             element={<RestaurantDocuments />}
//           />
//           <Route path="/contract" element={<Contract />} />
//           <Route path="/sign-document" element={<SignDocument />} />
//           <Route path="/complete-sign" element={<CompleteSign />} />
//         </Route>
//       </Routes>


//     </Router>
//   );
// }

// export default App;


import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Registration from "./components/Registration";
import RestaurantDocuments from "./components/RestaurantDocuments";
import MenuOperational from "./components/MenuOperational";
import Contract from "./components/Contract";
import SignDocument from "./components/SignDocument";
import CompleteSign from "./components/CompleteSign";
import RedirectToLastPage from "./utils/RedirectToLastPage";
import TrackLastPage from "./utils/TrackLastPage";
import Layout from "./components/Layout";
import useNetworkStatus from "./components/networkStatus"; // Import custom hook
import NetworkErrorPage from "./components/NerworkErrorPage"; // Import error page

function App() {
  const isOnline = useNetworkStatus(); // Use network status hook

  if (!isOnline) {
    // If offline, render the NetworkErrorPage
    return <NetworkErrorPage />;
  }

  return (
    <Router>
      <RedirectToLastPage />
      <TrackLastPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Layout />}>
          <Route path="/register" element={<Registration />} />
          <Route path="/menu-operational" element={<MenuOperational />} />
          <Route path="/restaurant-documents" element={<RestaurantDocuments />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/sign-document" element={<SignDocument />} />
          <Route path="/complete-sign" element={<CompleteSign />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
