

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { preGenerateAgreementVerifyOtp, signMerchantAgreementDocument, agreementSendOtp, agreementReSendOtp } from '../services/Api';
import { handleError } from '../services/errorHandler';
import { useNavigate } from 'react-router-dom';

const SignDocument = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [state, setState] = useState({
    showOtpModal: false,
    otp: Array(6).fill(''),
    isOtpVerified: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
    otpExpired: false,
    agreementOtpExpiry: 0,
    agreementOtpResendWindow: 0,
    maxResendAttempts: 3,
    isResendEnabled: false,
    showOtpExpiredMessage: false,
    errorMessage: "",
    isChecked: false,
    documentUrl: localStorage.getItem("documentUrl")
  });

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear current digit only

      // Update the OTP state
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));

      // Move focus to the previous input, but don't clear it
      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));
      inputRefs.current[index]?.focus();
    }
  };

  // Handle OTP Resend and Expiry Timers
  useEffect(() => {
    const currentTime = Date.now();
    const timeUntilExpiry = state.agreementOtpExpiry - currentTime;
    const timeUntilResend = state.agreementOtpResendWindow - currentTime;

    if (timeUntilExpiry > 0) {
      const expiryTimer = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          otpExpired: true,
          showOtpExpiredMessage: true,
          isResendEnabled: prevState.maxResendAttempts > 0,
        }));
      }, timeUntilExpiry);

      // Clear timer on unmount or state change
      return () => clearTimeout(expiryTimer);
    }

    if (timeUntilResend > 0) {
      const resendTimer = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isResendEnabled: true,
        }));
      }, timeUntilResend);

      return () => clearTimeout(resendTimer);
    }
  }, [state.agreementOtpExpiry, state.agreementOtpResendWindow, state.maxResendAttempts]);

  const handleSendOtp = async () => {
    try {
      const response = await agreementSendOtp(state.restaurantId);
      const { otpExpiry, otpResendWindow, uniqueAttemptId, totalAttempts } = response.otp;

      localStorage.setItem("agreementOtpExpiry", Date.now() + otpExpiry * 1000);
      localStorage.setItem("agreementOtpResendWindow", Date.now() + otpResendWindow * 1000);
      localStorage.setItem("agreementUniqueAttemptId", uniqueAttemptId);
      localStorage.setItem("agreementOtpRetriesLeft", totalAttempts);

      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(''),
        agreementOtpExpiry: Date.now() + otpExpiry * 1000,
        agreementOtpResendWindow: Date.now() + otpResendWindow * 1000,
        maxResendAttempts: totalAttempts,
        showOtpModal: true,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleResendOtp = async () => {
    if (!state.isResendEnabled || state.maxResendAttempts <= 0) return;

    try {
      const response = await agreementReSendOtp(state.restaurantId, localStorage.getItem("agreementUniqueAttemptId"));
      const { otpExpiry, otpResendWindow } = response;

      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(''),
        otpExpired: false,
        isResendEnabled: false,
        agreementOtpExpiry: Date.now() + otpExpiry * 1000,
        agreementOtpResendWindow: Date.now() + otpResendWindow * 1000,
        maxResendAttempts: prevState.maxResendAttempts - 1,
      }));
      inputRefs.current[0]?.focus();
    } catch (error) {
      handleError(error);
    }
  };

  const handleVerifyOtp = async () => {
    if (state.otpExpired) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: "OTP expired. Please resend to get a new one.",
      }));
      setTimeout(() => setState((prevState) => ({ ...prevState, errorMessage: "" })), 5000);
      return;
    }

    try {
      const response = await preGenerateAgreementVerifyOtp({
        agreementType: "Merchant_Onboarding",
        otp: state.otp.join(""),
        restaurantId: state.restaurantId,
        requestId: localStorage.getItem("agreementUniqueAttemptId"),
      });

      setState((prevState) => ({
        ...prevState,
        showOtpModal: false,
        isOtpVerified: true,
      }));
      localStorage.setItem("email", response.ownerEmailId);
      navigate('/complete-sign');

    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col items-center mt-20 w-full">
        <div className=" shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
          <div className="w-full">
            <iframe
              src={state.documentUrl}
              title="Document"
              className="w-full h-screen border-0"
            ></iframe>
          </div>
          <div className="w-full p-6 flex flex-col items-center bg-gray-100">
            <div className="checkbox-container flex items-center mt-4">
              <input
                type="checkbox"
                checked={state.isChecked}
                onChange={() => setState((prevState) => ({ ...prevState, isChecked: !prevState.isChecked }))}
              />
              <label className="text-sm ml-2">I have read and understood the terms stipulated in the
                merchant agreement. I consent to the terms outlined.</label>
            </div>
            <button
              onClick={handleSendOtp}
              disabled={!state.isChecked}
              className={`text-white mt-5 px-4 py-2 rounded ${state.isChecked ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
            >
              Proceed to Sign
            </button>
          </div>
        </div>
      </main>

      {state.showOtpModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">OTP Verification</h2>
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showOtpModal: false,
                  }))
                }
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="text-center">
              <p className="text-gray-500">Check text messages for your OTP</p>
              <div className="flex justify-center mt-4 space-x-2">
                {state.otp && state.otp.length > 0 && state.otp.map((digit, index) => (
                  <input key={index} type="text" maxLength="1"
                    className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified}
                  />
                ))}
              </div>
              {state.errorMessage && <p className="text-red-500 font-bold mt-2">{state.errorMessage}</p>}
              <div className="mt-2">
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleVerifyOtp}
                  disabled={state.otp.join('').length !== 6}
                >
                  Verify OTP
                </button>
              </div>
              <div className="mt-2 text-gray-500">
                Not received OTP?{' '}
                <button
                  className={`text-red-500 font-bold hover:underline ${state.isResendEnabled && state.maxResendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleResendOtp}
                  disabled={!state.isResendEnabled || state.maxResendAttempts <= 0}
                >
                  Resend Now
                </button>
                {state.maxResendAttempts <= 0 && (
                  <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignDocument;
